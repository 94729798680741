// LAYOUT
$width-big: 1024px;
$width-normal: 733px;
$width-small: 580px;
$width-800: 800px;
$width-930: 930px;
$width-vsmall: 483px;
$width-se: 375px;
$width-iphone12: 414px;
$width-popup: 540px;
$height-popup: 500px;
$width-popup-terms: 984px;
$height-popup-terms: 500px;

// COLOR
$white: #ffffff;
$black: #000000;
$grey: #919a9e;
$red: #ff5873;
$orange: #ff884d;
$color-grey-light: #f2f2f2;
$color-dark-blue: #234557;
$color-main: #000000;
$color-bg: #ffffff;
$color-a: #000000;
$color-disabled: #cccccc;
$color-error: $red;
$color-light-green: #d6eef0;
$color-popup-bg-out: rgba(79, 79, 79, 0.64);
$color-popup-bg-in: $white;
$color-line-bg: #d3dadd;
$color-filezone-border: #cdcace;
$color-filezone-text: #333;
$color-filezone-button-border: #828282;
//colors button
$color-button-next: $color-dark-blue;
$color-button: $color-dark-blue;
$color-button-hover: #d6eef0;
$color-button-white: $white;
$color-button-text: $white;
$color-button-text-white: $black;
$color-button-link-blue: $color-dark-blue;
$color-hover-btn-white: #d3dadd;
$color-button-dashboard: #111;
//forms
$color-checkbox: #1d1d1b;
$color-desc: $grey;
$color-yesno-yes: $color-dark-blue;
$color-yesno-no: #b6b6b6;
$color-yesno-notvalid: $red;
$color-select-hover: #00a5cd;
$color-select-selected: $color-dark-blue;
//theme
$color-header-bg: $color-dark-blue;
$color-header-secure-bg: $color-light-green;
$color-bottom-bg: rgba(255, 255, 255, 0.8);
$color-bottom-links-bg: #f2f2f2;
//popups
$color-popup-green: #77b62a;
$color-popup-red: $red;
//elements
$color-youtube-border: $grey;
$color-sms-verification-timer: $orange;
$color-contract-deleted: #f2f2f2;
$color-contract-provider-image-bg: #f2f2f2;
$color-contract-provider-image-border: #e0e0e0;
$color-contract-provider-image-text: #4f4f4f;
$item-border: #e0e0e0;
$color-orange: rgba(255, 95, 88, 0.7);
//statuses
$color-red: #ff5f58;
$color-open: #919a9e;
$color-reject: $red;
$color-onwork: #f3ca3e;
$color-activate: #d6eef0;

// TYPOGRAPHY
$font-main: 'Gotham SSm', sans-serif;
$font-gotham-round: 'Gotham Rounded', sans-serif;

$font-size-10: 10px;
$font-size-11: 11px;
$font-size-12: 12px;
$font-size-13: 13px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-28: 28px;
$font-size-30: 30px;

$line-height-10: 1.1;
$line-height-11: 1.27273;
$line-height-12: 12px;
$line-height-13: 1.38462;
$line-height-14: 14px;
$line-height-16: 1.375;
$line-height-18: 2;
$line-height-20: 20px;
$line-height-22: 22px;
$line-height-24: 1.33334;
$line-height-28: 28px;
$line-height-36: 36px;
