@import './variables';
@import './mixins';

.onboarding-contract-add-page {
    .firms {
        @include row-center-center;
        margin-top: 32px;

        img {
            padding: 0px 16px;
        }
    }
    h2 {
        @include font-style-h1;
        padding-bottom: 5px;
    }
}
.onboarding-products-page,
.onboarding-providers-page {
    .search,
    .search-input-element {
        margin-bottom: 20px;
    }
    .header {
        h1 {
            @include font-style-h1;
            padding: 10px 0px 20px;
        }
    }
}
.onboarding-products-page,
.onboarding-providers-page > {
    .search {
        padding-bottom: 20px;
    }
    .content > {
        padding-bottom: 100px;
        .wrapper {
            padding: 16px 0px 50px;
        }
        .selected-item {
            background-color: $color-light-green;
            padding: 20px 0px;
            .wrapper {
                @include row-left-center;
                span {
                    padding: 5px;
                    @include font-size-16;
                    font-weight: 700;
                }
            }
            @include media($width-big) {
                padding: 0px;
            }
        }
    }
}
.onboarding-providers-page > {
    .custom-provider {
        position: fixed;
        bottom: 30px;
        width: 100%;
        background-color: $color-grey-light;

        .content {
            padding-bottom: 1px !important;
            .wrapper {
                padding: 0px 0px;
            }
        }

        @include media($width-normal) {
            .title,
            input {
                padding-left: 16px !important;
            }
        }
    }

    .bottom {
        bottom: 100px;
    }
}
.onboarding-contract-edit-page {
    .line {
        width: 100%;
        height: 1px;
        background-color: $color-line-bg;
        margin: 0px;
    }
    h1 {
        padding: 40px 0px 20px;
    }
    .provider-element {
        background-image: url('../assets/icon-pen.png');
        background-position: center right;
        background-repeat: no-repeat;
    }
}

.onboarding-contract-list-page {
    .products {
        h1 {
            padding: 0px;
            @include media($width-vsmall) {
                @include font-size-16;
                font-weight: 400;
            }
        }
        .button {
            .inner {
                .text {
                    text-transform: capitalize;
                }
            }
        }
    }
    .contracts {
        padding: 4px 0px 30px;
        .item {
            @include row-left-top;
            margin-top: 16px;
            //padding: 16px 13px 5px 20px;
            box-sizing: border-box;
            border-radius: 8px;
            //border: 2px solid $black;
            border: 2px solid $color-dark-blue;
            flex-wrap: wrap;
            align-content: stretch;
            align-items: stretch;

            .provider-icon {
                padding: 16px 0px 5px 20px;

                @include media($width-iphone12) {
                    display: none;
                }
            }

            &.deleted {
                background-color: $color-contract-deleted;
            }

            .texts {
                flex: 1;
                margin-left: 16px;
                padding: 16px 0px 5px 20px;

                @include media($width-se) {
                    width: 220px;
                }

                @include media($width-iphone12) {
                    max-width: auto;
                    padding: 16px 0 5px 2px;
                }

                .product {
                    @include font-size-16;
                    font-weight: 500;
                    line-height: $line-height-20;
                    padding-bottom: 5px;
                }
                .provider {
                    @include font-size-12;
                    font-weight: 300;
                    line-height: $line-height-14;

                    .contract-number {
                        padding-left: 50px;

                        @include media($width-normal) {
                            padding-left: 5px;
                        }
                    }
                }

                .edit-link {
                    @include row-left-center;
                }
            }

            .selecting {
                @include column-center-center;
                background: $color-dark-blue;
                width: 150px;
                border-bottom-right-radius: 4px;
                border-top-right-radius: 4px;

                .text {
                    padding-top: 5px;
                    @include font-size-12;
                    text-align: center;
                    color: $white;
                }
                &.red {
                    .text {
                        color: $color-error;
                    }
                    .checkbox .checkbox-inner .element {
                        border-color: $color-error;
                    }
                }
                @include media($width-vsmall) {
                    width: 100%;
                    @include row-left-center;
                    padding: 5px 0px;
                    border-bottom-left-radius: 4px;
                    border-top-right-radius: 0px;

                    .text {
                        font-weight: 700;
                        padding-top: 0px;
                    }
                }
            }

            .text-disable {
                color: $grey;
            }
        }
    }
}

.onboarding-contract-list-finish-page {
    .header-texts {
        margin-left: 65px;
        @include media($width-vsmall) {
            margin-left: 0px;
            margin-top: 10px;
        }
    }
    .header-text {
        @include font-size-14;
        line-height: $line-height-20;
        color: $white;
        font-weight: 300;
        background: url('../assets/icon-checkbox-white.png') left center no-repeat;
        margin-bottom: 30px;
        padding-left: 44px;
    }
    .content {
        margin-top: 32px;
        @include media($width-normal) {
            margin-top: 0px;
        }
    }
}

.onboarding-sign-broker-page,
.onboarding-sign-privacy-page {
    .link-text {
        @include font-size-14;

        span {
            cursor: pointer;
        }

        a {
            text-decoration: underline;
        }
    }
    .container-sign {
        width: 100%;
        height: 307px;
        border: 2px solid $black;
        border-radius: 8px;
        margin: 20px 0;
        position: relative;

        .inner {
            min-height: 250px;
            .signCanvas {
                width: 100%;
                //height: 100%;
                //aspect-ratio: auto auto;
            }
            .signCanvasMobile {
                //border: 1px solid blue;
                //height: 100%;
                //aspect-ratio: auto 350 / 260;
            }
        }

        .container-sign-bottom {
            @include row-spacebetween-center;
            padding: 0 5px 0 24px;

            .name {
                @include font-size-14;
                font-weight: 600;
            }

            .remove-sign {
                @include touchArea;
                background: url('../assets/icon-trash.png') center center no-repeat;
            }
        }

        .youtube-video {
            position: absolute;
            right: -180px;
            top: 5px;
            display: inline-block;
        }
    }

    .bottom-text {
        @include font-size-14;
        margin-top: 10px;
        padding-left: 40px;
        background: url('../assets/icon-check-black.png') left center no-repeat;
    }

    .conditions {
        margin-top: 10px;

        .item {
            width: 100%;
            margin-top: 20px;
            .form-yesno {
                margin-top: 5px;
            }
        }
    }
    .container-sign-text {
        background: $color-light-green;
        padding-bottom: 20px;
        margin-top: 40px;
        > div {
            @include font-size-16;
            font-weight: 400;
            padding-top: 20px;
            @include media($width-normal) {
                @include font-size-14;
            }
        }
    }
}
.onboarding-sign-privacy-page {
    .top-text {
        @include font-size-14;
        line-height: 20px;
    }
    .bottom-text {
        @include font-size-14;
        line-height: 20px;
        margin-top: 10px;
        padding-left: 0px;
        background: none;
    }
}
.onboarding-finapi-import-page {
    .finapi-webform {
        overflow: hidden;
        height: 100%;
        width: 100%;
    }
    .complete {
        @include media($width-normal) {
            @include font-size-16;
        }
    }
}
