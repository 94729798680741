@import './variables';
@import './mixins';

.signup-step-1-page {
    .bottom-list-text {
        background: $color-light-green;
        ul {
            margin-top: 57px;
            padding: 10px 10px;

            li {
                @include font-size-16;
                font-weight: 300;
                line-height: 20px;
                color: $color-a;
                list-style: none;
                padding: 10px 0px 10px 30px;
                background: url('../assets/icon-check-black.png') -14px center no-repeat;
            }
        }
    }
}

.signup-step-4-page,
.signin-page,
.auth-start-page,
.auth-forgot-send-page,
.auth-forgot-confirm-page {
    background-color: $color-dark-blue;

    .header {
        padding-top: 50px;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: unset;

        .top {
            @include row-center-center;

            img {
                &.logo {
                    width: 180px;
                    height: 40px;
                }
            }
        }
    }

    .content {
        .wrapper {
            @include font-size-14;
            background-color: $color-bg;
            border-radius: 8px;
            padding: 20px 0px 50px;
            margin-top: 40px;

            @include media($width-normal) {
                border-radius: 0px;
            }

            h1 {
                padding-bottom: 10px;
            }

            .button-link {
                height: 19px;
                display: block;
                margin-top: 15px;

                &.margin-top-70 {
                    margin-top: 70px;
                }
            }

            .row-center {
                @include row-center-center;
            }
        }
    }

    .readonly-field {
        .text {
            @include font-size-14;
            border: 0px;
            height: 28px;
            width: 100%;
            line-height: 28px;
            font-weight: bold;
            color: $color-a;
            padding: 0px;
        }

        .inner {
            background-image: unset;
        }
    }

    .margin-top-12 {
        margin-top: 12px;
    }
}

.wrapper-top-inner,
.wrapper-steps {
    @include row-spacebetween-center;

    .form-steps-separator-line {
        width: 33px;
        height: 3px;
        background-color: $color-dark-blue;
    }

    .form-steps-circle {
        @include font-size-20;
        background-color: $color-bg;
        color: $color-dark-blue;
        width: 29px;
        height: 29px;
        line-height: 27px;
        text-align: center;
        border-radius: 50%;
        font-weight: bold;
        font-size: 16px;
        border: 2px solid $color-dark-blue;

        &.active {
            color: $white;
            background-color: $color-dark-blue;
        }
    }
}
.wrapper-top-inner {
    @include media($width-small) {
        @include column-left-top;
    }
}
.wrapper-steps {
    @include media($width-small) {
        margin-top: 20px;
    }
}

.sms-verification {
    margin-top: 25px;

    .wrapper-inputs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
    }

    .timer-text-sms-verif {
        color: $color-sms-verification-timer;
        font-weight: bold;
        margin-left: 10px;
    }

    input[type='text'] {
        @include font-size-16;
        display: block;
        width: 122px;
        height: 62px;
        border: 2px solid $color-a;
        border-radius: 8px;
        text-align: center;

        @include media($width-normal) {
            width: 60px;
        }

        &.one-code {
            @include font-size-18;
            width: 100%;
            letter-spacing: 30px;
            font-weight: 700;
            padding-left: 30px;
        }

        &.redBorder {
            border-color: $color-popup-red;
        }
    }

    .sms-verification-text-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}

.app {
    &.signup-step-4-page,
    &.signin-page,
    &.auth-start-page,
    &.auth-forgot-send-page,
    &.auth-forgot-confirm-page {
        @include scroll-whole-page($color-header-bg);
    }
}
