.button-link {
    @include row-left-center;
    @include font-size-14;
    font-weight: 300;
    text-decoration: underline;
    cursor: pointer;

    &.small {
        @include font-size-10;
    }

    &.blue {
        @include font-size-12;
        color: $color-button-link-blue;
    }

    &.red {
        color: $color-error;
    }
}

.button {
    @include column-center-center;
    padding: 20px 30px;
    box-sizing: border-box;
    display: inline-block;
    cursor: pointer;
    background: $color-button;
    border: 1px solid $color-button;
    border-radius: 8px;

    &:hover,
    &:focus {
        background: $color-button-hover;
    }

    &:hover {
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
    }

    .inner {
        @include row-center-center;
        .text {
            @include font-size-16;
            font-weight: 500;
            line-height: 20px;
            text-align: center;
            text-transform: uppercase;
            color: $color-button-text;

            .info {
                @include font-size-12;
                text-transform: none;
                color: $color-dark-blue;
                padding-top: 5px;
                font-weight: 300;
                line-height: 14px;
                @include media($width-vsmall) {
                    display: none;
                }
            }
        }
        .left-icon {
            display: inline-block;
            margin-right: 16px;
        }
    }

    &.right-icon,
    &.right-icon-black {
        padding-right: 50px;
        background-image: url('../assets/icon-arrow-right.png');
        background-position: center right;
        background-repeat: no-repeat;
        min-width: 200px;
        @include media($width-vsmall) {
            padding: 20px 20px;
        }
    }
    &.right-icon-black {
        background-image: url('../assets/icon-arrow-right-black.png');
    }

    &.white {
        background-color: $color-button-white;
        border: 2px solid $black;

        .inner {
            .text {
                color: $color-dark-blue;
            }
        }

        &:hover {
            background-color: $color-hover-btn-white;
        }
    }

    &.transparent {
        background-color: transparent;
        border: 2px solid $black;
        color: $black;

        &.right-icon {
            background-image: url('../assets/icon-arrow-right-black.png');
        }

        .inner {
            .text {
                color: $black;
            }
        }

        &:hover {
            background-color: $color-hover-btn-white;
        }
    }

    &.transparent-white {
        background-color: transparent;
        border: 2px solid $white;

        .inner {
            .text {
                color: $white;
            }
        }

        &:hover {
            background-color: $color-hover-btn-white;
        }
    }

    &.left-align {
        @include row-left-center;
        padding-left: 16px;
        .inner {
            @include row-left-center;
            .text {
                text-align: left;
            }
        }
    }

    &.full-width {
        padding: 20px 20px;
        width: 100%;
        @include media($width-small) {
            padding: 20px 30px 20px 12px;
        }
    }

    &.margin-none {
        margin: 0px;
    }

    &.padding-none {
        padding: 0px;
    }

    &.width-200 {
        width: 200px;
    }

    @include media($width-small) {
        .inner {
            .text {
                width: auto;
            }
        }
    }

    @include media($width-se) {
        .inner {
            .text {
                width: 230px;
            }
        }
    }
}

.button-dashboard {
    @include font-size-14;
    font-weight: 500;
    padding: 10px 4px 10px 10px;
    border: 2px solid $color-button-dashboard;
    border-radius: 6px;
    cursor: pointer;
    min-height: 148px;
    min-width: 120px;
    margin-right: 10px;
    box-sizing: border-box;

    &.white {
        background: $white;
    }

    &.no-right-margin {
        margin-right: 0px;
    }

    .text {
        padding-top: 10px;
        padding-left: 4px;

        .title {
            width: 90px;
        }

        .number {
            @include font-size-20;
            font-weight: 700;
        }

        .click-icon {
            padding-right: 4px;
            img {
                width: 8px;
            }
        }
    }
    .button-dashboard-values {
        @include row-left-center;
    }
    .button-dashboard-values > {
        div {
            margin-right: 10px;
        }
    }

    @include media($width-iphone12) {
        min-width: 100px;
    }
    @include media($width-800) {
        min-height: 100px;
        width: 48%;
        margin-right: 0px;
        .button-dashboard-item {
            @include row-left-center;
        }
        .click-icon {
            display: none;
        }
    }
}
.button-new {
    @include column-center-center;
    @include font-size-12;
    text-transform: uppercase;
    box-sizing: border-box;
    border: 1px solid $black;
    border-radius: 8px;
    height: 45px;
    display: inline-block;
    padding: 15px 16px 4px 44px;
    cursor: pointer;
    background: url('../assets/icon-new-plus.png') no-repeat center left;
}
