.header {
    background: $color-header-bg;
    width: 100%;
    padding-bottom: 5px;
    position: sticky;
    top: 0;
    z-index: 100;

    .top,
    .top-with-back {
        @include column-right-center;
        height: 70px;
        padding-right: 20px;
        width: 100%;

        .logo {
            width: 180px;
            height: 40px;
        }

        @include media($width-small) {
            padding-right: 0px;
        }
    }

    .top-with-back {
        @include row-spacebetween-center;

        .back {
            @include touchArea;
            background: url('../assets/icon-arrow-left-white.png');
            cursor: pointer;
        }
    }

    .margin-top {
        margin-top: 80px;
    }

    .expert {
        margin: 20px 0px 40px;
        width: 100%;

        .h1 {
            @include font-style-h1;
            color: $white;
            margin-left: auto;
            margin-right: auto;
            width: $width-vsmall;
            @include media($width-vsmall) {
                width: auto;
            }
        }

        .wrapper {
            @include row-left-center;

            img: {
                width: 55px;
            }

            .texts {
                padding-left: 22px;
                .titleExpert {
                    @include font-size-20;
                    font-weight: 300;
                    color: $white;
                    padding-bottom: 16px;
                }
                .title {
                    @include font-size-14;
                    font-weight: 500;
                    line-height: 20px;
                    color: $white;
                }
                .text {
                    padding-top: 5px;
                    @include font-size-14;
                    font-weight: 300;
                    line-height: 24px;
                    color: $white;
                }
            }
        }

        &.expert-info {
            .wrapper {
                @include row-left-top;
                @include wrapper-xy($width-vsmall);

                .text {
                    @include font-size-16;
                    font-weight: 500;
                    line-height: 28px;
                    //color: $white;
                }

                @include media($width-vsmall) {
                    @include column-center-center;

                    img {
                        padding-top: 16px;
                    }

                    .texts {
                        margin-top: 12px;
                        @include column-center-center;
                        padding-left: 0px;

                        .text {
                            margin-top: 12px;
                            @include font-size-14;
                            text-align: center;
                        }
                    }
                }
            }
        }

        .close,
        .open {
            margin-top: 10px;
            width: 44px;
            height: 44px;
            background: url('../assets/icon-close-transparent.png') center center;
            cursor: pointer;
        }

        @include media($width-small) {
            margin: 0px 0px 10px;
            @include column-center-center;
            .wrapper {
                @include column-center-center;

                .texts {
                    text-align: center;
                    padding-left: 0px;

                    .title {
                        margin: 20px 0px 10px;
                    }
                }
            }
        }

        &.expert-closed {
            .open {
                background: url('../assets/icon-dropdown-white.png') center center;
            }
            @include media($width-small) {
                @include row-spacebetween-end;
                .wrapper {
                    flex: 1;
                    @include row-left-center;
                    margin: 0px;

                    img {
                        width: 40px;
                    }

                    .texts {
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    h1 {
        @include font-size-20;
        font-weight: 600;
        color: $white;
        padding-bottom: 10px;
    }

    &.header-mobile {
        .expert {
            &.expert-info {
                .wrapper {
                    padding: 0px;
                }
            }
        }
    }
}

.header-secure {
    background: $color-header-secure-bg;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 100;

    h1 {
        padding: 20px 0px 30px;
    }

    .top {
        @include row-left-center;
        min-height: 44px;
        border-bottom: 1px solid $color-dark-blue;
        @include media($width-small) {
            border-bottom: 0px;
        }

        .left {
            width: 81px;

            .back {
                @include touchArea;
                background: url('../assets/icon-arrow-left-black-small.png') no-repeat center left;
                cursor: pointer;
            }
        }
        .right {
            @include row-right-center;
            flex: 1;

            .menu-top {
                @include row-right-center;

                div {
                    padding: 16px 10px;
                    cursor: pointer;
                    @include font-size-12;
                    font-weight: 500;

                    &.selected,
                    &:hover {
                        background: $color-dark-blue;
                        color: $white;
                    }
                    &:hover {
                        transition: all 0.5s;
                        -webkit-transition: all 0.5s;
                    }
                    @include media($width-small) {
                        @include column-center-center;
                        height: 44px;
                    }
                }
            }
            .menu-slide-icon {
                @include column-center-center;
                height: 44px;
                width: 44px;
                cursor: pointer;
                img {
                    height: 44px;
                    width: 44px;
                }
            }
        }
    }

    &.no-border {
        .top {
            border: 0px;
        }
    }
    .children {
        padding-bottom: 16px;
        h1 {
            color: $black;
        }
        h3 {
            @include font-style-h1;
            color: $black;
        }
    }
    &.header-mobile {
        .wrapper-dashboard {
            @include media($width-big) {
                padding: 0px;
                .children {
                    padding: 0px 16px 16px 16px;
                }
            }
        }
    }
}

.bottom {
    background: $color-bottom-bg;
    width: 100%;
    padding: 14px 0;
    position: fixed;
    bottom: 30px;
    left: 0;

    .wrapper-big {
        @include row-right-center;
        padding-bottom: 0px;
    }
}

.bottom-links {
    @include column-center-center;
    height: 30px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: $color-bottom-links-bg;
    z-index: 200;

    .wrapper {
        @include row-spacearound-center;
        width: 230px;

        @include media($width-iphone12) {
            width: 250px;
        }
    }

    &.bottom-links-blue {
        background: $color-header-bg;

        .button-link {
            color: $white;
        }
    }
    .popup {
        .button-link {
            color: $black;
        }
    }
}

.menu-slide {
    width: 150px;
    height: 100vh;
    box-sizing: border-box;
    background: $color-dark-blue;
    position: fixed;
    right: -150px;
    top: 0px;
    z-index: 10000;
    padding: 4px 10px 16px 0px;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;

    &.opened {
        transform: translate3d(-150px, 0, 0);
        animation-timing-function: ease-in;
    }
    .menu-slide-inner {
        @include column-right-top;

        .icon-menu {
            cursor: pointer;
            img {
                width: 44px;
                height: 44px;
            }
        }

        .item {
            color: $white;
            @include font-size-12;
            text-transform: uppercase;
            padding-right: 5px;
            line-height: 44px;
            height: 44px;
            cursor: pointer;
        }
    }
}
