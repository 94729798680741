@font-face {
    font-family: 'Gotham Rounded';
    src: url('../assets/fonts/GothamRounded-Light.woff2') format('woff2'), url('../assets/fonts/GothamRounded-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham SSm';
    src: url('../assets/fonts/GothamSSm-BlackItalic.woff2') format('woff2'), url('../assets/fonts/GothamSSm-BlackItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham SSm';
    src: url('../assets/fonts/GothamSSm-Bold.woff2') format('woff2'), url('../assets/fonts/GothamSSm-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham SSm';
    src: url('../assets/fonts/GothamSSm-BoldItalic.woff2') format('woff2'), url('../assets/fonts/GothamSSm-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham SSm Book';
    src: url('../assets/fonts/GothamSSm-Book.woff2') format('woff2'), url('../assets/fonts/GothamSSm-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham SSm';
    src: url('../assets/fonts/GothamSSm-Light.woff2') format('woff2'), url('../assets/fonts/GothamSSm-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham SSm';
    src: url('../assets/fonts/GothamSSm-LightItalic.woff2') format('woff2'), url('../assets/fonts/GothamSSm-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham SSm';
    src: url('../assets/fonts/GothamSSm-Medium.woff2') format('woff2'), url('../assets/fonts/GothamSSm-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham SSm';
    src: url('../assets/fonts/GothamSSm-MediumItalic.woff2') format('woff2'), url('../assets/fonts/GothamSSm-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham SSm Extra';
    src: url('../assets/fonts/GothamSSm-ExtraLight.woff2') format('woff2'), url('../assets/fonts/GothamSSm-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham SSm Extra';
    src: url('../assets/fonts/GothamSSm-ExtraLightItalic.woff2') format('woff2'), url('../assets/fonts/GothamSSm-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}
