//popups
.popup {
    @include column-center-center;
    position: fixed;
    background: $color-popup-bg-out;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    top: 0px;
    left: 0px;
    z-index: 1000;

    .container {
        @include column-top-stretch;
        background: $color-popup-bg-in;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 30px;
        width: $width-popup;
        height: $height-popup-terms;

        @include media($width-popup-terms) {
            width: 95%;
            padding: 16px;
        }

        @include media-y($height-popup-terms) {
            height: 200px;
        }

        .container-top {
            .title {
                @include font-size-16;
                font-weight: 500;
                padding-bottom: 40px;
                text-transform: uppercase;
            }
        }
        .container-inner {
            flex: 1;
            @include font-size-16;
            font-weight: 300;

            .scroll {
                @include column-center-center;
                width: 100%;
                overflow-y: scroll;

                @include media-y($height-popup) {
                    height: 84px;
                }
            }
        }
        .container-bottom {
            @include row-right-center;
            padding-top: 40px;

            .right-btn {
                margin-left: 16px;
            }

            @include media($width-vsmall) {
                @include column-center-center;
                .right-btn {
                    margin-left: 0px;
                    margin-top: 10px;
                }
            }
        }
    }

    &.popup-auto-height {
        .container {
            height: auto;
        }
    }

    &.popup-terms {
        .container {
            .container-top {
                height: 80px;

                .button-link {
                    padding-bottom: 5px;
                }
            }

            .container-inner {
                flex: 1;

                .scroll {
                    display: block;
                    height: $height-popup-terms - 220;
                    @include media-y($height-popup-terms) {
                        height: 84px;
                    }
                }
            }
        }
    }
}

.alerts {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 459px;
    margin-top: 10px;
    margin-right: 16px;
    z-index: 200;

    @include media(459px) {
        width: auto;
        margin-left: 16px;
    }

    .item {
        @include row-spacebetween-center;
        padding: 16px 5px 16px 20px;
        box-sizing: border-box;
        border-radius: 8px;
        background-color: $color-popup-green;
        margin-top: 3px;

        &.error {
            background-color: $color-popup-red;
        }

        .message {
            @include font-size-14;
            line-height: $line-height-22;
            font-weight: 500;
            color: $white;
            flex: 1;
        }

        .close {
            @include touchArea;

            img {
                width: 44px;
                height: 44px;
            }
        }
    }
}
