// variables
@import './fonts';
@import './variables';
@import './mixins';

// globals
@import './keyframes';
@import './normalize';
@import './reset';
@import './global';
@import './modifier';
@import './form';
@import './popups';
@import './elements';
@import './buttons';
@import './themes';

.app {
    background-color: $white;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    height: 100vh;
    overflow-y: hidden;

    .content {
        padding-bottom: 150px;
        flex: 1;
        overflow-y: scroll;
    }

    span {
        &.bold {
            font-weight: bold;
        }

        &.underline {
            text-decoration: underline;
        }
    }

    .loading-icon {
        @include column-center-center;
        margin-top: 64px;
    }

    &.onboarding-finapi-error-page,
    &.onboarding-finapi-restart-page,
    &.general-message-page {
        background-color: $color-header-bg;
    }

    @include media($width-iphone12) {
        @include scroll-whole-page;
    }

    .btn-auth-register {
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 100;
        @include media($width-small) {
            display: none;
        }
    }
}

.wrapper {
    @include wrapper-xy();

    @include media($width-vsmall) {
        //padding: 0px;
    }
}

.wrapper-small {
    @include wrapper-xy($width-small);
}

.wrapper-vsmall {
    @include wrapper-xy($width-vsmall);
}

.wrapper-big {
    @include wrapper-xy($width-big);
    @include media($width-small) {
        &.wrapper-big-without-padding {
            padding: 0px;
        }
    }
}

.wrapper-buttons {
    @include media($width-small) {
        padding: 0px;
        width: auto;
    }
}

.hidden {
    @include hidden;
}

.display-inline {
    display: inline-block;
}
