.checkbox {
    @include row-left-center;
    @include font-size-14;

    .checkbox-inner {
        @include column-center-center;
        width: 44px;
        height: 44px;

        .element {
            @include column-center-center;
            width: 24px;
            height: 24px;
            border: 2px solid $color-checkbox;
            border-radius: 12px;

            &.checked {
                background: url('../assets/icon-checkbox.svg') center center no-repeat;
            }
        }
    }

    .title {
        @include font-size-16;
        font-weight: 500;
    }

    &.checkbox-white {
        color: $white;

        .checkbox-inner {
            .element {
                border: 2px solid $white;
            }

            .element {
                &.checked {
                    background: url('../assets/icon-checkbox-white.png') center center no-repeat;
                }
            }
        }
    }

    &.red {
        color: $color-error;

        .checkbox-inner {
            .element {
                border: 2px solid $color-error;
            }
        }
    }

    &.disable {
        color: $grey;

        .checkbox-inner {
            .element {
                border: 2px solid $grey;
            }
        }
    }
    @include media($width-small) {
        .title {
            width: 390px;
        }
    }

    @include media($width-se) {
        .title {
            width: 270px;
            padding-bottom: 10px;
        }
    }
}

.checkboxlist {
    width: 100%;

    .checkbox {
        cursor: pointer;
    }
}

.input-text,
.input-date {
    width: 100%;
    box-sizing: border-box;
    margin-top: 30px;
    .inner {
        width: 100%;
        border-bottom: 1px solid $color-line-bg;
        height: 37px;
        background-image: url('../assets/icon-pen.png');
        background-position: center right;
        background-repeat: no-repeat;
        padding-right: 50px;
        box-sizing: border-box;

        .title {
            @include font-size-12;
            font-weight: 300;
            text-transform: uppercase;

            &.red {
                color: $color-error;
            }
        }

        input {
            @include font-size-16;
            border: 0px;
            height: 24px;
            width: 100%;
            line-height: 28px;
            font-weight: 500;
            color: $black;
            background: none;
            padding: 0px;

            @include media($width-normal) {
                @include font-size-16;
            }

            @include placeholder {
                @include font-size-14;
                color: $black;
            }

            &:focus {
                outline: none;
            }
        }
    }
    .error {
        @include font-size-12;
        font-weight: 500;
        color: $color-error;
        padding-top: 2px;
    }
    .desc {
        @include font-size-12;
        font-weight: 500;
        color: $color-desc;
        padding-top: 2px;
    }
}

.input-date {
    position: relative;

    .inner {
        @include row-left-center;
        background: none;
        padding-right: 0px;
        .container-inner {
            flex: 1;
        }
        .btn-icon {
            height: 44px;
            width: 44px;
            background-image: url('../assets/icon-calendar.png');
            background-position: center center;
            background-repeat: no-repeat;
        }
    }

    .picker {
        position: absolute;
        right: 0px;
    }
}

.form-files {
    margin-top: 20px;
    width: 100%;

    .title {
        @include font-size-12;
        font-weight: 300;
        text-transform: uppercase;
    }

    .dropzone {
        @include column-center-center;
        width: 100%;
        border: 1px dashed $color-filezone-border;
        border-radius: 5px;
        padding: 20px 0px;
        margin-top: 10px;

        .text {
            @include font-size-12;
            color: $color-filezone-text;
            text-align: center;
        }

        .btn {
            @include font-size-12;
            display: inline-block;
            text-transform: uppercase;
            color: $color-filezone-text;
            text-align: center;
            border: 1px solid $color-filezone-button-border;
            border-radius: 5px;
            padding: 9px 6px;
            margin-top: 16px;
            cursor: pointer;

            &:hover {
                background-color: $color-hover-btn-white;
                transition: all 0.3s;
                -webkit-transition: all 0.3s;
            }
        }
    }
    .list {
        width: 100%;

        .list-item {
            @include row-spacebetween-center;

            .name {
                @include font-size-16;
                font-weight: 500;
            }
            .btnRemove {
                @include touchArea;
                background: url('../assets/icon-trash.png') center center no-repeat;
            }
        }
    }
}

.form-select {
    width: 100%;
    box-sizing: border-box;
    margin-top: 20px;
    .inner {
        width: 100%;
        border-bottom: 1px solid $color-line-bg;
        height: auto;
        background: none;
        padding-right: 0px;
        box-sizing: border-box;

        &.no-border {
            border-bottom: 0px;
        }

        .title {
            @include font-size-12;
            font-weight: 300;
            text-transform: uppercase;
            position: relative;
            top: 12px;

            &.red {
                color: $color-error;
            }
        }

        .selectcomp__control {
            @include row-spacebetween-center;
            border: none !important;
            background: none;
            box-shadow: none !important;

            .selectcomp__indicators {
                border: none !important;
                background: none;
                display: inline-block;
                padding: 0px;

                .selectcomp__indicator {
                    padding: 0px;

                    .btn-drop {
                        width: 44px;
                        height: 44px;
                        background: url('../assets/icon-select.png') no-repeat;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .error {
        @include font-size-12;
        font-weight: 500;
        color: $color-error;
        padding-top: 2px;
    }

    .selectcomp__menu > {
        .selectcomp__menu-list > {
            .selectcomp__option {
                &.selectcomp__option--is-focused {
                    background-color: $color-select-hover;
                }
                &.selectcomp__option--is-selected {
                    background-color: $color-select-selected;
                    color: $white;
                }
                &:hover {
                    background-color: $color-select-hover;
                    transition: all 0.3s;
                    -webkit-transition: all 0.3s;
                }
            }
        }
    }
}

.form-yesno {
    width: 67px;
    min-width: 67px;
    height: 30px;
    box-sizing: border-box;
    cursor: pointer;

    .inner {
        @include row-spacebetween-center;
        width: 100%;
        height: 100%;
        background-color: $color-yesno-yes;
        border: 2px solid $color-yesno-yes;
        border-radius: 60px;

        .circle {
            box-sizing: border-box;
            height: 26px;
            width: 26px;
            border-radius: 13px;
            background-color: $white;
        }

        .text {
            @include font-size-12;
            color: $white;
            font-weight: 700;
            text-align: center;
            width: 33px;
        }

        &.inner-no {
            background-color: $color-yesno-no;
            border: 2px solid $color-yesno-no;
        }
    }
    &.not-valid {
        .inner {
            border: 2px solid $color-yesno-notvalid;
        }
    }
}
